<template>
    <div class="container flex flex-col a-dashboard">
        <div v-if="!isNewUser" class="py-4">

            <div class="grid grid-cols-3 gap-4">
                <div class="col-span-1 mb-4">
                    <router-link to="/Factsheets" class="rounded p-4 border border-grey-4 w-full flex justify-between items-center group hover:text-primary-light hover:bg-grey-5">
                        <div class="flex justify-between items-center">
                            <div class="w-12 lg:w-20 mr-4">
                                <IndicatorsIcon class="text-risk-matrix-green-dark" />
                            </div>
                            <h1 class="text-black text-lg lg:text-2xl">Factsheets</h1>
                        </div>
                        <fa-icon icon="chevron-right" class="text-grey-2" />
                    </router-link>
                </div>
                <div class="col-span-1 mb-4">
                    <router-link
                        to="/Resources"
                        class="rounded p-4 border border-grey-4 w-full flex justify-between items-center group hover:text-primary-light hover:bg-grey-5"
                    >
                        <div class="flex justify-between items-center">
                            <div class="w-12 lg:w-20 mr-4">
                                <FileIcon class="text-light-blue" />
                            </div>
                            <h1 class="text-black text-lg lg:text-2xl">Resources</h1>
                        </div>
                        <fa-icon icon="chevron-right" class="text-grey-2" />
                    </router-link>
                </div>
                <div class="mb-4 col-span-1">
                   <a href="#" @click.prevent="authorizeGoToWebinars"
                    class="rounded p-4 border border-grey-4 w-full flex justify-between items-center group hover:text-primary-light hover:bg-grey-5">
                        <div class="flex justify-between items-center">
                            <div class="w-12 lg:w-20 mr-4">
                                <WebinarsIcon class="text-dark-blue" />
                            </div>
                            <h1 class="text-black text-lg lg:text-2xl">Webinars</h1>
                        </div>
                        <fa-icon icon="chevron-right" class="text-grey-2" />
                    </a>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-4">
                <div class="rounded p-4 border border-grey-4 mb-4 col-span-2">
                    <div class="flex justify-between items-center">
                        <h1 class="text-black text-lg lg:text-2xl">My Practices</h1>
                        <router-link :to="{name: 'PracticeList'}"
                                    class="h-3 group flex items-center text-xs text-primary-light uppercase hover:text-primary flex-right"
                                    data-cy="addPracticeLink"
                        >
                            <span class="font-bold group-hover:text-primary-light mr-1">All Practices</span>
                            <fa-icon icon="chevron-right" class="h-3 mr-1 group-hover:text-primary-light"/>
                        </router-link>
                    </div>

                    <PracticeList @updated-practice-list="updatePracticeList" :page-size="5" :hide-search="true" :summary="true" />
                </div>

<!-- TODO: show the below table when we have the survey offers in Vue -->
<!--                &lt;!&ndash; Below is Surveyor user only&ndash;&gt;-->
<!--                <div class="rounded p-4 border border-grey-4" v-if="isAdmin || isSurveyor">-->
<!--                    <h1 class="text-black mb-3 text-xl lg:text-2xl">Survey Offers</h1>-->

<!--                    <p> table here</p>-->
<!--                </div>-->
            </div>
        </div>
        <FirstLogin v-else/>
    </div>
</template>

<script>
import FirstLogin from '@/components/practice-list/partials/FirstLogin'
import { AuthMixin } from '@/components/shared/mixins/authHelpers'
import BackendHelpers from '@/components/shared/mixins/backendHelpers'
import camelcaseKeys from 'camelcase-keys'
import HttpMixin from '@/components/shared/mixins/httpMixin'
import PracticeList from '@/components/practice-list/List'
import Webinars from '@/components/shared/mixins/webinars'
import WebinarsIcon from '@/assets/icons/icons_webinars.svg'
import IndicatorsIcon from '@/assets/icons/icons_indicators.svg'
import FileIcon from '@/assets/icons/icons_file.svg'
import { PAYMENT_TYPES } from '@/config/constants'

export default {
    name: "DashboardView",
    components: { FirstLogin, PracticeList, WebinarsIcon, IndicatorsIcon, FileIcon },
    mixins: [ AuthMixin, BackendHelpers, HttpMixin, Webinars ],
    methods: {
        async updatePracticeList(practiceList) {
            this.loading = false
            this.practiceList = practiceList
            if (this.practiceList && this.practiceList.length === 1) {
                const practice = this.practiceList[0]

                const reappStage = practice.hasReapplicationPaymentDue || practice.hasReapplicationDue
                if (practice.status.toUpperCase() === PAYMENT_TYPES.PaymentPending && !reappStage) {
                    await this.redirectOnPaymentPending(practice)
                }
                // else {
                //     this.$router.push({name: 'Staff', params: {id: practice.organisationPtrId}})
                // }
            }
        },
    }
}
</script>
